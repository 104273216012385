import { lazy } from 'react'
import { useParams, Route, Routes as RouterRoutes } from 'react-router-dom'

const Admin = lazy(() => import('containers/Admin'))
const Home = lazy(() => import('containers/Home'))
const Mint = lazy(() => import('containers/Mint'))
const Licenses = lazy(() => import('containers/Licenses'))
const Gallery = lazy(() => import('containers/Gallery'))
const Poap = lazy(() => import('containers/Poap'))
const Maguey = lazy(() => import('containers/Maguey'))
const Fair = lazy(() => import('containers/Fair'))
const Stake = lazy(() => import('containers/Stake'))
const Market = lazy(() => import('containers/Market'))
const Lsm = lazy(() => import('containers/Lsm'))
const Scan = lazy(() => import('containers/Scan'))
const Token = lazy(() => import('containers/Token'))
const Live = lazy(() => import('containers/Live'))
const PassProof = lazy(() => import('containers/PassProof'))
const Profile = lazy(() => import('containers/Profile'))
const CurrentAccount = lazy(() => import('containers/Profile/CurrentAccount'))
const Congratulations = lazy(() => import('containers/Congratulations'))
const Terms = lazy(() => import('containers/Terms'))
import NotFound from 'containers/NotFound'

import Layout from 'components/Layout'

import { hasFair, hasMarket, hasStaking } from 'lib/constants'

const ETH_ADDRESS_MATCH_REGEX =
	/^(0x[a-fA-F0-9]{40}|[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?)$/

function OtherRoute() {
	const params = useParams<any>()

	const route = params['*']

	const userId = route?.match(ETH_ADDRESS_MATCH_REGEX)?.[0]

	if (typeof userId === 'string') {
		return <Profile address={userId.toLowerCase() as `0x${string}`} />
	}

	const tokenId = Number(route?.match(/^(\d+)/)?.[0])

	if (typeof tokenId === 'number' && !isNaN(tokenId)) {
		return <Token id={tokenId} />
	}

	return <NotFound />
}

function Routes() {
	return (
		<RouterRoutes>
			<Route element={<Layout />}>
				<Route path="/" element={<Home />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/gallery/:id" element={<Token />} />
				<Route path="/live" element={<Live />} />
				<Route path="/token/:id" element={<Gallery />} />
				<Route path="/licenses" element={<Licenses />} />
				<Route path="/maguey" element={<Maguey />} />
				<Route path="/pass-proof" element={<PassProof />} />
				<Route path="/pass-proof/:eventId" element={<PassProof />} />
				<Route path="/profile" element={<CurrentAccount />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/scan" element={<Scan />} />
				<Route path="/lsm" element={<Lsm />} />
				<Route path="/poap/:id" element={<Poap />} />
				<Route path="/mint" element={<Mint />} />
				<Route path="/congratulations" element={<Congratulations />} />
				{Boolean(hasFair) && <Route path="/fair" element={<Fair />} />}
				{Boolean(hasStaking) && <Route path="/staking" element={<Stake />} />}
				{Boolean(hasMarket) && <Route path="/market" element={<Market />} />}
				<Route path="/_admin" element={<Admin />} />
				<Route path="/*" element={<OtherRoute />} />
			</Route>
		</RouterRoutes>
	)
}

export default Routes
