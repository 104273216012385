import { useTranslation } from 'react-i18next'

import { Container } from 'containers/Mint/styled'
import Button from 'components/Button'

import { Content } from 'GlobalStyled'

type Props = {
	error?: any
	message?: string
	title?: string
	isReloadShown?: boolean
}
function ErrorScreen(props: Props) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { error, message, title, isReloadShown = true } = props

	const { t } = useTranslation()
	// TODO: show Sentry feedback

	return (
		<Content withPadding>
			<Container gap="var(--space--small)">
				<h3>{title ?? t('screens.error.title')}</h3>
				<p style={{ textAlign: 'center' }}>
					{message ?? t('screens.error.description')}
				</p>
				{Boolean(isReloadShown) && (
					<Button onClick={() => void window.location.reload()}>
						{t('screens.error.reload')}
					</Button>
				)}
			</Container>
		</Content>
	)
}

export default ErrorScreen
