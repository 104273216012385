import * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { IS_DEV } from 'lib/constants'

import en from './en/common.json'
import es from './es/common.json'

const resources = {
	en: { common: en },
	es: { common: es },
}

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		ns: 'common',
		compatibilityJSON: 'v4',
		resources,
		debug: IS_DEV,
		fallbackLng: Object.keys(resources)[0],
		interpolation: { escapeValue: false },
	})
