/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useContext, useState, type ComponentProps } from 'react'
import * as ToastPrimitive from '@radix-ui/react-toast'
import styled, { keyframes } from 'styled-components/macro'

const hide = keyframes`
	0% { opacity: 1 }
	100% { opacity: 0 }
`

const slideIn = keyframes`
	from {
    transform: translateY(calc(-200% + var(--space--medium)));
  }
	to { transform: translateY(0); }
`

const swipeOut = keyframes`
	from { transform: translateY(var(--radix-toast-swipe-end-x)); }
	to { transform: translateY(calc(-200% + var(--space--medium))); }
`

const StyledViewport = styled(ToastPrimitive.Viewport)`
	position: fixed;
	top: 0;
	right: 50%;
	transform: translateX(50%);
	display: flex;
	flex-direction: column;
	padding: var(--space--medium);
	gap: 10px;
	width: 100%;
	max-width: 400px;
	margin: 0;
	list-style: none;
	z-index: 2147483647;
	outline: none;
`

const StyledToast = styled(ToastPrimitive.Root)`
	background-color: var(--colors--background_opposite_transparent);
	backdrop-filter: blur(15px);
	border-radius: var(--border_radius--medium);
	padding: var(--space--medium);
	display: grid;
	grid-template-areas: 'title action' 'description action';
	grid-template-columns: auto max-content;
	column-gap: 15px;
	align-items: center;

	@media (prefers-reduced-motion: no-preference) {
		&[data-state='open'] {
			animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
		}
		&[data-state='closed'] {
			animation: ${hide} 100ms ease-in;
		}
		&[data-swipe='move'] {
			transform: translateX(var(--radix-toast-swipe-move-x));
		}
		&[data-swipe='cancel'] {
			transform: translateX(0);
			transition: transform 200ms ease-out;
		}
		&[data-swipe='end'] {
			animation: ${swipeOut} 100ms ease-out;
		}
	}
`

const StyledTitle = styled(ToastPrimitive.Title)`
	grid-area: title;
	margin-bottom: var(--space--xxsmall);
	font-weight: 500;
	color: var(--colors--text_opposite);
	font-size: 0.9rem;
`

const StyledDescription = styled(ToastPrimitive.Description)`
	grid-area: description;
	margin: 0;
	color: var(--colors--text_opposite);
	font-size: 0.8rem;
	line-height: 1.3;
`

const StyledAction = styled(ToastPrimitive.Action)`
	grid-area: action;
	font-size: 0.8rem;
	color: var(--colors--text_opposite);
`

type ToastOptions = {
	title: string
	description?: string
	action?: { title?: string } & ComponentProps<typeof ToastAction>
}

const ToastContext = createContext({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	show: (_: ToastOptions): void => undefined,
})

export function ToastProvider({ children }: { children: any }) {
	const [toast, showToast] = useState<ToastOptions | null>(null)

	return (
		<ToastContext.Provider value={{ show: (options) => showToast(options) }}>
			<ToastPrimitive.Provider swipeDirection="up">
				{children}
				<Toast
					duration={5000}
					open={Boolean(toast)}
					onOpenChange={() => showToast(null)}
				>
					<ToastTitle>{toast?.title}</ToastTitle>
					{Boolean(toast?.description) && (
						<ToastDescription>{toast!.description}</ToastDescription>
					)}
					{Boolean(toast?.action) && (
						<ToastAction
							{...toast!.action}
							altText={toast!.action?.title ?? ''}
						>
							{toast!.action?.title ?? toast!.action?.children}
						</ToastAction>
					)}
				</Toast>
				<ToastViewport />
			</ToastPrimitive.Provider>
		</ToastContext.Provider>
	)
}

export function useToast() {
	return useContext(ToastContext)
}

export const ToastViewport = StyledViewport
export const Toast = StyledToast
export const ToastTitle = StyledTitle
export const ToastDescription = StyledDescription
export const ToastAction = StyledAction
export const ToastClose = ToastPrimitive.Close
