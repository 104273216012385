import styled, { keyframes, css } from 'styled-components/macro'

const pulse = keyframes`
	0% {
		opacity: 1;
	}
	25% {
		opacity: 0.5;
	}
	75% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
`

const Button = styled.button<{
	solid?: boolean | 'black'
	pulsing?: boolean
	borderColor?: string
	small?: boolean
	disabled?: boolean
	isIconOnly?: boolean
	color?: string
}>`
	border-radius: var(--border_radius--medium);
	min-height: ${({ small }) =>
		small ? 'var(--button_size--small)' : 'var(--button_size)'};
	min-width: ${({ small }) =>
		small ? 'var(--button_size--small)' : 'var(--button_size)'};

	padding: ${({ small }) =>
		small
			? 'var(--space--xxsmall) var(--space--small)'
			: 'var(--space--xsmall) var(--space--medium)'};

	border: 2px solid var(--colors--border);
	color: ${({ color }) => color ?? 'var(--colors--text)'};
	font-weight: 600;
	border-color: ${({ borderColor }) => borderColor};
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		opacity: 0.5;
		color: ${({ solid, color }) => (solid ? color : undefined)};
	}

	${({ solid, color }) =>
		solid &&
		`
		border-color: ${solid === true ? color ?? 'var(--colors--text)' : 'black'};
		background-color: ${solid === true ? color ?? 'var(--colors--text)' : 'black'};
		color: ${solid === true ? 'var(--colors--background)' : 'white !important'};
	`}

	${({ small }) =>
		small &&
		css`
			font-size: 0.75rem;
		`}

& > *:first-child:not(:last-child) {
		height: 18px;
		width: 18px;
		stroke-width: 2.5px;
		display: inline-block;
	}

	img,
	svg {
		margin-left: ${({ isIconOnly }) =>
			isIconOnly ? undefined : 'var(--space--small)'};
		width: 20px;
		height: 20px;
		stroke-width: 2.5px;
	}

	animation: ${({ pulsing }) =>
		pulsing
			? css`
					${pulse} 1.5s infinite
			  `
			: 'none'};
`

export default Button
