import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { ToastProvider } from 'components/Toast'

import 'lib/analytics'
import 'lib/logs'
import 'lib/sentry'

import './lib/locales'
import Routes from './Routes'

const queryClient = new QueryClient({
	defaultOptions: { queries: { staleTime: Infinity } },
})

function App() {
	return (
		<HelmetProvider>
			<BrowserRouter>
				<ToastProvider>
					<QueryClientProvider client={queryClient}>
						<Routes />
					</QueryClientProvider>
				</ToastProvider>
			</BrowserRouter>
		</HelmetProvider>
	)
}

export default App
