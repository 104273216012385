import { Link, useLocation } from 'react-router-dom'
import { MoreHorizontal } from 'react-feather'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/system'

import { ReactComponent as Logo } from 'assets/logo.svg'

import { ReactComponent as Discord } from 'assets/discord.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as Instagram } from 'assets/instagram.svg'

import * as Styled from './styled'

const LINKS = [
	{ href: import.meta.env.VITE_APP_TWITTER_URL, Img: Twitter, name: 'Twitter' },
	{ href: import.meta.env.VITE_APP_DISCORD_URL, Img: Discord, name: 'Discord' },
	{
		href: import.meta.env.VITE_APP_INSTAGRAM_URL,
		Img: Instagram,
		name: 'Instagram',
	},
].filter(Boolean)

const SocialLink = styled.a`
	padding: 5px;
	border-radius: 8px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	span {
		display: none;
	}
`

function MainNavItems() {
	const { pathname } = useLocation()
	const { t } = useTranslation()

	return (
		<Flex as="ul" gap="var(--space--small)" color="var(--color--text)">
			{pathname !== '/' && (
				<li>
					<Link
						aria-label={t('aria.nav.socialLink', { name: 'home' })}
						style={{ justifySelf: 'start' }}
						to="/"
					>
						<Logo width={40} height={30} aria-hidden />
					</Link>
				</li>
			)}
			{LINKS.map(({ name, Img, href }) => (
				<li key={name}>
					<SocialLink
						target="_blank"
						rel="noreferrer noopener"
						aria-label={t('aria.nav.socialLink', { name })}
						href={href}
					>
						<Img width={30} height={30} aria-hidden />
						<span>{name}</span>
					</SocialLink>
				</li>
			))}
		</Flex>
	)
}

function SkeletonWalletButton() {
	return (
		<Styled.StyledNav align="right" as="aside" fixed aria-hidden>
			<Styled.NavContent>
				<Styled.EnsButton hasAvatar={false} disabled>
					<Styled.EnsButtonContent hasAvatar={false}>
						<MoreHorizontal strokeWidth={2.5} />
					</Styled.EnsButtonContent>
				</Styled.EnsButton>
			</Styled.NavContent>
		</Styled.StyledNav>
	)
}

function SkeletonNav() {
	const { t } = useTranslation()

	return (
		<Styled.StyledNav aria-label={t('aria.nav.socialLinks')} align="left">
			<Styled.NavContent>
				<MainNavItems />
				<SkeletonWalletButton />
			</Styled.NavContent>
		</Styled.StyledNav>
	)
}

export { MainNavItems, SkeletonWalletButton }
export default SkeletonNav
