export default [
	{
		inputs: [
			{ internalType: 'bytes32', name: '_parentNode', type: 'bytes32' },
			{ internalType: 'bytes32', name: '_labelHash', type: 'bytes32' },
		],
		name: 'setUser',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		name: 'getNamehash',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32',
			},
		],
		inputs: [
			{
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
		],
		stateMutability: 'pure',
		type: 'function',
	},
	{
		name: 'paused',
		inputs: [],
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
] as const
