import { makeVar } from '@apollo/client'

import { StorageKeys } from 'lib/constants/enums'

export const cookieConsentVar = makeVar<'all' | 'necessary' | null>(
	(localStorage.getItem(StorageKeys.cookieConsent) as any) ?? null,
)

export const theme = makeVar<'dark' | 'light'>(
	(localStorage.getItem(StorageKeys.theme) as any) ?? 'dark',
)

export function toggleTheme() {
	theme(theme() === 'dark' ? 'light' : 'dark')
	localStorage.setItem(StorageKeys.theme, theme())
}
