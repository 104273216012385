import {
	ReactComponent as Opensea,
	default as opensea,
} from 'assets/opensea.svg'
import {
	ReactComponent as Looksrare,
	default as looksrare,
} from 'assets/looksrare.svg'
import {
	ReactComponent as Rarible,
	default as rarible,
} from 'assets/rarible.svg'
import { ReactComponent as X2y2, default as x2y2 } from 'assets/x2y2.svg'

enum MintPhase {
	Idle,
	Private,
	Public,
}

import nftAbi from './nft-abi'
import domainsABI from './domains'
import stakingAbi from './staking-abi'
import ensRegistryABI from './ensRegistry'
import { default as ensControllerABI } from './ensController'
import { default as ensPublicResolver } from './ensPublicResolver'

export const IS_DEV = import.meta.env.DEV

export const honoraryContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_HONORARY_ADDRESS
export const nftContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_NFT_ADDRESS
export const stakingContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_STAKING_ADDRESS
export const fairContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_FAIR_ADDRESS
export const marketContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_MARKET_ADDRESS
export const domainsContractAddress: `0x${string}` = import.meta.env
	.VITE_APP_DOMAINS_ADDRESS
export const publicResolverAddress: `0x${string}` = import.meta.env
	.VITE_APP_ENS_PUBLIC_RESOLVER

export const requiredChainId = import.meta.env.VITE_APP_CHAIN_ID
	? Number(import.meta.env.VITE_APP_CHAIN_ID)
	: null

export const IS_PROD = Number(requiredChainId) === 1

export const nftContractConfig = {
	address: nftContractAddress,
	abi: nftAbi,
}

export const ensResolverConfig = (
	address: `0x${string}` = publicResolverAddress,
) => ({
	address,
	abi: ensPublicResolver,
})

export const stakingContractConfig = {
	address: stakingContractAddress as `0x${string}`,
	abi: stakingAbi,
}

export const theyXoloDomainsConfig = {
	address: domainsContractAddress as `0x${string}`,
	abi: domainsABI,
}

export const ensControllerContractConfig = {
	address: '0x283af0b28c62c092c9727f1ee09c02ca627eb7f5' as `0x${string}`,
	abi: ensControllerABI,
}

export const ensRegistryContractConfig = {
	address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' as `0x${string}`,
	abi: ensRegistryABI,
}

export const PRIVATE_MINT_DATE = new Date('2022-10-27T05:00:00.000Z')
export const PUBLIC_MINT_DATE = new Date('2022-11-01T05:00:00.000Z')
export const REVEAL_DATE = new Date('2022-11-09T05:00:00.000Z')

export const MARKETPLACES = [
	{
		assetDomain: (contract: string, token: string, chain = 'ethereum') =>
			`https://opensea.io/assets/${chain}/${contract}/${token}`,
		getUserUrl: (address: string) => `https://opensea.io/accounts/${address}`,
		href: import.meta.env.VITE_APP_OPENSEA_URL,
		Icon: Opensea,
		img: opensea,
		name: 'OpenSea',
	},
	{
		assetDomain: (contract: string, token: string) =>
			`https://x2y2.io/eth/${contract}/${token}`,
		getUserUrl: (address: string) => `https://x2y2.io/user/${address}`,
		href: import.meta.env.VITE_APP_X2Y2_URL,
		Icon: X2y2,
		img: x2y2,
		name: 'X2Y2',
	},
	{
		assetDomain: (contract: string, token: string) =>
			`https://looksrare.org/collections/${contract}/${token}`,
		getUserUrl: (address: string) =>
			`https://looksrare.org/accounts/${address}`,
		href: import.meta.env.VITE_APP_LOOKSRARE_URL,
		Icon: Looksrare,
		img: rarible,
		name: 'Rarible',
	},
	{
		assetDomain: (contract: string, token: string) =>
			`https://rarible.com/token/${contract}:${token}`,
		getUserUrl: (address: string) => `https://rarible.com/user/${address}`,
		href: import.meta.env.VITE_APP_RARIBLE_URL,
		Icon: Rarible,
		img: looksrare,
		name: 'LooksRare',
	},
]

export const BASE_API_URL = `/api`

export const BURN_ADDRESS = '0x000000000000000000000000000000000000dead'

export const hasStaking = Boolean(stakingContractAddress)
export const hasMinting = Boolean(nftContractAddress)
export const hasMarket = Boolean(marketContractAddress)
export const hasFair = Boolean(fairContractAddress)

export { ensControllerABI, ensRegistryABI, nftAbi, stakingAbi, MintPhase }
