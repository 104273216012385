import { Content } from '@radix-ui/react-dialog'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'

import { scaleAndShow } from 'modules/WalletDrawer/styled'

import Button from 'components/Button'
import { Grid } from 'components/system'

const BACKGROUND_COLORS = [
	'--colors--pina',
	'--colors--mandarina',
	'--colors--pitaya',
	'--colors--lichi',
	'--colors--manzana',
	'--colors--chicle',
	'--colors--uva',
	'--colors--verde',
]

export const RemainingHeading = styled.h2`
	font-size: 1.25rem;
	opacity: 0.7;
	animation: ${scaleAndShow} 0.3s ease-out;
`

export const TokensContainer = styled.div`
	width: 150px;
	height: 150px;
	position: relative;
`

export const MintImage = styled.img<{ $totalCount?: number; $key?: number }>`
	width: 150px;
	height: 150px;
	border-radius: 30px;
	background-color: var(
		${({ $key = 1 }) => BACKGROUND_COLORS[$key % BACKGROUND_COLORS.length]}
	);
	position: absolute;
	box-shadow: 0 0 30px
		${({ $key = 1 }) =>
			transparentize(
				// eslint-disable-next-line no-magic-numbers
				0.3,
				getComputedStyle(document.documentElement).getPropertyValue(
					`${BACKGROUND_COLORS[$key % BACKGROUND_COLORS.length]}`,
				),
			)};
	transition: transform 0.6s;
`

export const CounterButton = styled(Button)`
	width: var(--button_size);
	height: var(--button_size);
	padding: 0;
	line-height: 0;
	background-color: var(--colors--border);
	border-radius: 50%;
	font-weight: 500;
	font-size: 1.4rem;

	&[disabled] {
		opacity: 0.3;
	}

	svg {
		margin: 0;
		stroke-width: 4px !important;
		width: 22.5px;
		height: 22.5px;
	}
`

export const Container = styled(Grid)`
	min-height: 100%;
	justify-content: center;
	align-content: center;
	align-items: center;
	justify-items: center;
`

export const MintButton = styled(Button)`
	background-color: var(--colors--golden);
	padding: var(--space--small) var(--space--large);
	border: none;
	border-radius: var(--border_radius--full);
	font-weight: 700;
	transition: background-color 0.2s ease-in-out;
	font-variant-numeric: lining-nums;
	box-shadow: var(--colors--golden_glow);
	color: black;
	margin: 0 auto var(--space--medium);
	flex-direction: column;
	min-width: 150px;

	:hover {
		background-color: var(--colors--mandarina);
	}

	span {
		display: block;
		margin: 0 auto;
		font-size: 0.6rem;
		text-transform: uppercase;
		opacity: 0.5;
		height: 7px;
	}
`

export const StyledContent = styled(Content)`
	background-color: var(--colors--background);
	border-radius: 6px;
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90vw;
	max-width: 450px;
	max-height: 85vh;
	padding: 25px;

	&:focus {
		outline: none;
	}
`
