import styled, { keyframes } from 'styled-components/macro'
import * as Dialog from '@radix-ui/react-dialog'
import { NavLink } from 'react-router-dom'

import { Flex } from 'components/system/index'
import device from 'lib/constants/devices'

const contentShow = keyframes`
 from {
   opacity: 0.5;
   transform: translate(100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`

export const scaleAndShow = keyframes`
 from {
	 opacity: 0.5;
	 transform: scale(0.8);

 }
 to {
	 opacity: 1;
	 transform: scale(1);
 }
`

export const AddressContainer = styled(Flex)`
	flex: 1;
	align-items: center;
	justify-content: flex-start;
	gap: var(--space--small);
	font-weight: 600;
	margin: 0 !important;
	padding: 0 !important;
	border-radius: var(--border_radius--full) !important;
`

export const GasLink = styled.a`
	color: black;
	display: flex;
	align-items: center;
	gap: var(--space--xxsmall);
	margin: 0 !important;
	font-size: 0.9rem;
	padding: 0 var(--space--medium) !important;
	font-weight: 700;
	border-radius: var(--border_radius--medium) !important;
	animation: ${scaleAndShow} 0.3s ease-out;

	span {
		font-size: 0.7rem;
	}
`

export const DrawerLink = styled(NavLink)<{ $golden?: boolean }>`
	&:focus {
		box-shadow: none;
	}

	${({ $golden }) =>
		typeof $golden === 'boolean' &&
		`
	b {
		font-weight: 800;
		background-color: ${
			$golden ? 'var(--colors--golden)' : 'var(--colors--background_opposite)'
		};
		box-shadow: ${$golden ? 'var(--colors--golden_glow)' : undefined};
		color: ${$golden ? 'black' : 'var(--colors--text_opposite)'};
		padding: 4px 12px;
		border-radius: 14px;
		font-size: 1.2rem;
		margin-left: -4px;
	}
	`}
`

export const ListContainer = styled.ul`
	flex-shrink: 0;
	border: 2px solid var(--colors--border);
	border-radius: 10px;
	margin: 0;
	overflow: hidden;

	li:not(:last-child) {
		border-bottom: 2px solid var(--colors--border);
	}

	button {
		display: flex;
		width: 100%;
		cursor: pointer;
		text-align: left;
		background-color: var(--colors--background_alternate);
		gap: var(--space--small);
		padding: 8px 14px;
		justify-content: flex-start;

		&[disabled] {
			opacity: 0.4;
		}

		&:hover:not([disabled]) {
			background-color: var(--colors--border);
		}
	}
`

export const Content = styled(Dialog.Content)`
	display: flex;
	position: fixed;
	backdrop-filter: blur(15px);
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	padding: var(--space--medium);
	padding-bottom: max(var(--space--medium), env(safe-area-inset-bottom));
	flex-direction: column;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
	background-color: var(--colors--background_transparent);
	overflow: auto;
	z-index: 9999;

	a {
		padding: var(--space--xsmall) var(--space--medium);
		margin: 0 calc(-1 * var(--space--medium));
		border-radius: 0;

		&:hover {
			background-color: var(--colors--border);
		}
		h3 {
			margin: 0;
			width: 100%;
			text-align: left;
		}
	}

	p {
		font-size: 0.8rem;
	}

	@media (prefers-reduced-motion: no-preference) {
		animation: ${contentShow} 500ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
	}

	@media ${device.mobileL} {
		max-width: 400px;
		margin: var(--space--xsmall);
		margin-right: max(var(--space--xsmall), env(safe-area-inset-right));
		margin-top: max(var(--space--xsmall), env(safe-area-inset-top));
		margin-bottom: max(var(--space--xsmall), env(safe-area-inset-bottom));
		border-radius: var(--space--medium);
		/* Reset padding bottom, margin-bottom already adds safe area */
		padding-bottom: var(--space--medium);
		width: calc(100% - calc(var(--space--small) * 2));
	}
`

export const AddressButton = styled.button`
	gap: var(--space--small);
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: space-between;
	font-weight: 700;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

	:active {
		transform: scale(0.975);
		opacity: 0.5;
	}
`

export const SoonLabel = styled.span`
	background-color: var(--colors--golden);
	border-radius: var(--border_radius--medium);
	line-height: 1;
	color: black;
	font-weight: 900;
	margin: 0 8px;
	padding: 4px 6px;
	font-size: 0.5rem;
`

export const LaterLabel = styled(SoonLabel)`
	background-color: var(--colors--background);
	color: var(--colors--text);
`

export const SectionsListContainer = styled(Flex)`
	font-size: 1.25rem;
	align-items: flex-start;
	flex-direction: column;

	a {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: calc(100% + calc(var(--space--medium) * 2));
		text-align: left;

		&:hover,
		&:focus {
			background-color: var(--colors--background);
		}
		&:active {
			transform: none;
			background-color: transparent;
		}
	}
`

export const SuggestedConnector = styled.b`
	flex-shrink: 0;
	font-size: 0.7rem;
`

export const ConnectorDescription = styled.span`
	display: block;
	margin: 0;
	font-size: 0.55rem;
	white-space: nowrap;
	overflow: hidden;
`

export const ConnectorImage = styled.img`
	width: 30px;
	height: 30px;
	margin-right: 5px;
	object-fit: contain;
`
