import { useTranslation } from 'react-i18next'

import { Content } from 'GlobalStyled'
import { Flex } from 'components/system'

function NotFound() {
	const { t } = useTranslation()

	return (
		<Content withPadding>
			<Flex flexDirection="column" alignItems="center">
				<h1>{t('screens.notFound.title')}</h1>
				<p>{t('screens.notFound.description')}</p>
			</Flex>
		</Content>
	)
}

export default NotFound
