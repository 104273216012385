import { lazy, Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

const TokenDialog = lazy(() => import('modules/TokenDialog'))

import SkeletonNav from 'modules/Nav/Skeleton'
import ErrorBoundary from 'modules/ErrorBoundary'
const Nav = lazy(() => import('modules/Nav'))
const Providers = lazy(() => import('modules/Providers'))

import Footer from 'components/Footer'

import { theme } from 'lib/apollo/variables'

import { GlobalStyle } from 'GlobalStyled'
import Spinner from 'components/Spinner'

const Main = styled.main`
	min-height: calc(100vh - var(--nav-height));
	display: grid;
	background-color: var(--colors--background);
`

function PlaceholderMain() {
	return (
		<>
			<SkeletonNav />
			<Main>
				<Spinner />
			</Main>
		</>
	)
}

function Layout() {
	const { pathname } = useLocation()
	const { i18n } = useTranslation()

	const { search } = useLocation()
	const currentTheme = useReactiveVar(theme)

	const { nft, burn, transfer } = qs.parse(search, { ignoreQueryPrefix: true })

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return (
		<>
			<Helmet htmlAttributes={{ lang: i18n.language }}>
				<meta
					name="theme-color"
					content={currentTheme === 'dark' ? '#000000' : '#ffffff'}
				/>
			</Helmet>
			<GlobalStyle $theme={currentTheme} />
			<Suspense fallback={<PlaceholderMain />}>
				<Providers>
					<Nav />
					<Main>
						{Boolean(nft) && (
							<Suspense fallback={null}>
								<TokenDialog
									id={nft as string}
									burn={burn === ''}
									transfer={transfer === ''}
								/>
							</Suspense>
						)}
						<ErrorBoundary>
							<Outlet />
						</ErrorBoundary>
					</Main>
				</Providers>
			</Suspense>
			<Footer />
		</>
	)
}

export { PlaceholderMain }
export default Layout
