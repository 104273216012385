import { Component } from 'react'
import { captureException } from '@sentry/react'

import ErrorScreen from 'modules/Error'

interface Props {
	children: any
}

interface State {
	hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error: any, errorInfo: any) {
		captureException(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorScreen />
		}

		return this.props.children
	}
}

export default ErrorBoundary
