import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import logo from 'assets/logo.svg'

import device from 'lib/constants/devices'
import { StorageKeys } from 'lib/constants/enums'

const FooterContent = styled.div`
	display: grid;
	gap: var(--space--large);
	grid-template-columns: 1fr;
	justify-items: center;
	align-items: end;
	max-width: var(--page-max-width);
	padding: var(--space--large) var(--space--medium);
	padding-bottom: max(var(--space--large), env(safe-area-inset-bottom));
	padding-right: max(var(--space--medium), env(safe-area-inset-right));
	padding-left: max(var(--space--medium), env(safe-area-inset-left));
	margin: 0 auto;
	font-size: 0.9rem;
	flex-direction: column;

	ul {
		justify-content: center;
	}

	p {
		margin-bottom: 0;
		i {
			font-family: 'Kanit';
		}
	}

	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr 1fr;

		ul {
			justify-content: right;
		}

		> *:first-child {
			justify-self: start;
		}
		> *:last-child {
			justify-self: end;
		}
	}
`

const Legal = styled.div`
	ul {
		margin-top: var(--space--small);

		a,
		button {
			margin-top: auto;
			min-height: 25px;
			padding: 3px 0;
			background-color: transparent;

			&:hover {
				opacity: 1;
			}
		}
	}

	button {
		background-color: rgba(255, 255, 255, 0.1);
		&[data-active='true'] {
			background-color: rgba(255, 255, 255, 0.4);
		}
	}
`

const FooterContainer = styled.footer`
	background-color: var(--colors--background_alternate);
`

const LanguageList = styled.li`
	display: flex;
	gap: 4px;
	margin-bottom: 12px;
	justify-content: center;

	button {
		border-radius: var(--border_radius--small);
		background-color: transparent !important;
		padding: var(--space--xxsmall) var(--space--xsmall);
		&[data-active='false'] {
			opacity: 0.5;
		}
	}

	@media ${device.tablet} {
		justify-content: start;
	}
`

function Footer() {
	const {
		t,
		i18n: { language, changeLanguage },
	} = useTranslation()

	function handleChangeLanguage(newLang: string) {
		localStorage.setItem(StorageKeys.i18nLng, newLang)
		window.scrollTo({ top: 0, behavior: 'smooth' })
		changeLanguage(newLang)
	}

	return (
		<FooterContainer>
			<FooterContent>
				<Legal>
					<ul aria-label={t('aria.footer.languagesList')}>
						<LanguageList>
							<button
								onClick={() => handleChangeLanguage('en')}
								data-active={language === 'en'}
							>
								English
							</button>
							<button
								onClick={() => handleChangeLanguage('es')}
								data-active={language === 'es'}
							>
								Español
							</button>
						</LanguageList>
					</ul>
					<ul
						style={{
							display: 'flex',
							gap: 5,
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
						}}
					>
						{Boolean(import.meta.env.VITE_APP_NFT_ADDRESS) && (
							<li>
								<a
									target="_blank"
									rel="noreferrer noopener"
									href={`https://etherscan.io/address/${
										import.meta.env.VITE_APP_NFT_ADDRESS
									}`}
								>
									<b>{t(`contract`)}</b>
								</a>
								{' •'}
							</li>
						)}
						<li>
							<a href="https://learn.theyxolo.art">{t('learn')}</a> •
						</li>
						<li>
							<Link to="/terms">{t('terms')}</Link> •
						</li>
						<li>
							<a href="https://mejor.theyxolo.art">{t('generator')}</a>
						</li>
					</ul>
				</Legal>

				<div>
					<p
						style={{
							marginBottom: 'var(--space--xsmall)',
							textAlign: 'center',
						}}
					>
						{t('madeWith')} <i>Mucho Cariño</i>
					</p>
					<p style={{ textAlign: 'center', fontSize: '0.8rem' }}>
						&copy; {new Date().getFullYear()} They Xolo
					</p>
					<p
						aria-label={t('aria.footer.appVersion', {
							version: __APP_VERSION__,
						})}
						style={{
							textAlign: 'center',
							fontSize: '0.7rem',
							fontWeight: '400',
							opacity: 0.6,
							marginTop: 8,
						}}
					>
						v{__APP_VERSION__}
					</p>
				</div>
				<a aria-label={t('aria.footer.home')} href="/">
					<img src={logo} style={{ width: 60 }} alt="" />
				</a>
			</FooterContent>
		</FooterContainer>
	)
}

export default Footer
