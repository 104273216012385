/* eslint-disable no-console */
import { nftContractAddress, requiredChainId } from 'lib/constants'

console.log(
	'%cTheyXolo',
	'font-weight: bold; font-size: 50px;color: red; text-shadow: 2px 2px 0 rgb(217,31,38) , 4px 4px 0 rgb(226,91,14) , 6px 6px 0 rgb(245,221,8) , 8px 8px 0 rgb(5,148,68) , 10px 10px 0 rgb(2,135,206) , 12px 12px 0 rgb(4,77,145)',
)
console.log(
	`Contract info:\n
  %cAddress: ${nftContractAddress}
  Chain: ${requiredChainId}\n`,
	'font-size: 0.85rem;font-weight:bold;font-family:monospace;',
)
console.log(
	`Project info:\n
  %cDiscord: ${import.meta.env.VITE_APP_DISCORD_URL}
  LooksRare: ${import.meta.env.VITE_APP_LOOKSRARE_URL}
  Rarible: ${import.meta.env.VITE_APP_RARIBLE_URL}
  OpenSea: ${import.meta.env.VITE_APP_OPENSEA_URL}
  Twitter: ${import.meta.env.VITE_APP_TWITTER_URL}
  Instagram: ${import.meta.env.VITE_APP_INSTAGRAM_URL}\n`,
	'font-size: 0.85rem;font-weight:bold;font-family:monospace;',
)
