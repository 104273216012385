import { posthog } from 'posthog-js'

import { IS_DEV } from 'lib/constants'

posthog.init('phc_v5xe0YA85SSp2Gvi8XCrm2u9XhgAdaQ1nE2BqvtGsv2', {
	api_host: 'https://app.posthog.com',
})

window?.addEventListener('appinstalled', () => {
	capture('app-installed')
})

export function capture(event: string, properties?: any) {
	if (IS_DEV) return
	posthog.capture(event, properties)
}

export function identify(id?: string | null) {
	if (IS_DEV || !id) return
	posthog.identify(id)
}
