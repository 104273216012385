import styled from 'styled-components/macro'

import Button from 'components/Button'

import device from 'lib/constants/devices'

export const StyledNav = styled.nav<{
	fixed?: boolean
	align: 'right' | 'left'
}>`
	position: relative;
	background-color: transparent;
	padding: 0 var(--space--medium);
	padding: 0 max(var(--space--medium), env(safe-area-inset-right)) 0
		max(var(--space--medium), env(safe-area-inset-left));
	color: white;
	top: 0;
	left: 0;
	height: var(--nav-height);
	inset: 0;
	z-index: 1;
	margin-right: ${({ align }) => (align === 'right' ? 'auto' : '0')};
	margin-left: ${({ align }) => (align === 'left' ? 'auto' : '0')};
	position: ${({ fixed }) => (fixed ? 'fixed' : undefined)};
	${({ fixed }) => fixed && `left: auto;`}

	a,
  b {
		z-index: 1;
	}

	button,
	a {
		padding: var(--space--xsmall) var(--space--small);
		font-weight: 600;
		backdrop-filter: blur(10px);
		transition: background-color 0.3s ease;
		border-radius: var(--border_radius--medium);
		min-height: var(--button_size);
		min-width: var(--button_size);
	}

	ul {
		display: flex;
		align-items: center;
		gap: 8px;
	}
`

export const NavContent = styled.div`
	display: grid;
	justify-content: space-between;
	justify-content: space-between;
	align-content: center;
	height: 100%;
	color: var(--colors--text);
	font-weight: 700;

	a:hover,
	a:focus {
		background-color: var(--colors--border_subtle);
	}
`

export const EnsButtonContent = styled.div<{ hasAvatar: boolean }>`
	padding: ${({ hasAvatar }) =>
		hasAvatar
			? '0 var(--space--medium) 0 var(--space--xsmall)'
			: '0 var(--space--medium)'};
`

export const EnsButton = styled(Button)<{
	hasAvatar: boolean
}>`
	position: relative;
	justify-self: flex-end;
	min-height: var(--button_size);
	border: none;
	padding: 0 !important;
	border-radius: var(--border_radius--full) !important;
	color: var(--colors--text_opposite);
	background-color: var(--colors--background_opposite_transparent);
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

	svg {
		margin: 0;
		display: block;
		width: 24px !important;
		height: 24px !important;
	}

	img {
		background: var(--colors--background_alternate);
		border-radius: 50%;
		width: 50px;
		height: 50px;
		min-width: var(--button_size);
		min-height: var(--button_size);
		margin: 0;
		scale: 1;

		@media ${device.tablet} {
			scale: 1.2;
		}
	}

	div {
		padding: ${({ hasAvatar }) =>
			hasAvatar
				? 'var(--space--xsmall) var(--space--small) var(--space--xsmall) var(--space--xsmall)'
				: undefined};
		display: ${({ hasAvatar }) => (hasAvatar ? 'none' : undefined)};
		@media ${device.tablet} {
			display: initial;
		}
	}
`
